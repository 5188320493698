import React, { useEffect, useState } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import axios from "axios";
import UserGallery from "./UserGallery";
import UserSubmission from "./UserSubmission";
import { API_URL } from "../config.js";
import { useCookies } from "react-cookie";

const Login = () => {
    const [form, setForm] = useState({
        validated: false,
        key: "",
        isError: false,
        error: "",
    });

    const [galleryData, setGalleryData] = useState(false);
    const [submissionData, setSubmissionData] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies();

    useEffect(() => {
        const checkAuth = async () => {
            const response = await authorize(cookies.gallery_key);
            if (response.status === 200) {
                if (response.data.is_ready == 1) {
                    setGalleryData(response.data);
                } else {
                }
            } else {
                removeCookie("gallery_key");
            }
        };
        if (cookies.gallery_key) {
            checkAuth();
        }
    }, [cookies]);

    const authorize = async (access_key) => {
        try {
            const res = await axios.post(API_URL + "/gallery/auth", {
                access_key,
            });

            return {
                status: res.status,
                data: res.data,
            };
        } catch (e) {
            return {
                status: e.response.status,
            };
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (form.key === "") {
            setForm({
                ...form,
                isError: true,
                error: "Gallery key is required",
                validated: true,
            });
            return;
        } else {
            const response = await authorize(form.key);
            if (response.status === 200) {
                if (response.data.is_ready == 1) {
                    setGalleryData(response.data);
                    setCookie("gallery_key", form.key);
                } else if (response.data.is_picked) {
                    setForm({
                        ...form,
                        key: "",
                        isError: true,
                        error: "You've already submitted photos",
                    });
                } else {
                    setSubmissionData(response.data);
                }
            } else if (response.status === 401) {
                setForm({
                    ...form,
                    key: "",
                    isError: true,
                    error: "Invalid key",
                });
            } else {
                setForm({
                    ...form,
                    key: "",
                    isError: true,
                    error: "Server error",
                });
            }
        }
    };

    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    };

    if (galleryData) {
        return <UserGallery galleryData={galleryData} />;
    } else if (submissionData) {
        return <UserSubmission submissionData={submissionData} />;
    } else {
        return (
            <Row className="mt-40">
                <Col md="12">
                    <Image src="logo.png" className="h-56 m-auto" />
                    <Row className="mt-8">
                        <Col
                            md={{ span: 2, offset: 5 }}
                            xs={{ span: 10, offset: 1 }}
                        >
                            <Form
                                noValidate
                                validated={form.validated}
                                onSubmit={handleSubmit}
                            >
                                <Form.Group controlId="groupKey">
                                    <Form.Label>
                                        Please enter your gallery key:
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="key"
                                        value={form.key}
                                        required
                                        onChange={handleChange}
                                        isInvalid={form.isError}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {form.error}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Button
                                    variant="secondary"
                                    type="submit"
                                    className="mx-auto d-block"
                                >
                                    Enter
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
};

export default Login;
