import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./tailwind.css";
import "./custom.css";
import Login from "./components/Login";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Share from "./components/Share";
import { CookiesProvider } from "react-cookie";

function App() {
    return (
        <CookiesProvider>
            <Router>
                <Switch>
                    <Route path="/:shareLink">
                        <Share />
                    </Route>
                    <Route path="/">
                        <Login />
                    </Route>
                </Switch>
            </Router>
        </CookiesProvider>
    );
}

export default App;
