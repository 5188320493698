import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

library.add(faHeart);
const Footer = () => {
    return (
        <p className="text-center mt-8 mb-2">
            © Regina Foster, 2020. Created with&nbsp;
            <FontAwesomeIcon icon="heart" />
            &nbsp;by&nbsp;<a href="mailto:mail@dennisfoster.us">Dennis Foster</a>
        </p>
    );
};

export default Footer;
