import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import UserGallery from "./UserGallery";
import { API_URL } from "../config.js";

const Share = () => {
    const { shareLink } = useParams();
    const [galleryData, setGalleryData] = useState(false);
    const [error, setError] = useState(0);

    useEffect(() => {
        const authorize = async (shareLink) => {
            try {
                const res = await axios.post(API_URL + "/gallery/auth_share", { share_key: shareLink });

                if (res.data) {
                    setGalleryData(res.data);
                } else {
                    setError(500);
                }
            } catch (e) {
                if (e.response.status === 401) {
                    setError(401);
                } else {
                    setError(500);
                }
            }
        };

        authorize(shareLink);
    }, []);

    if (galleryData) {
        return <UserGallery galleryData={galleryData} />;
    }

    if (error === 401) {
        return (
            <div style={{ margin: "20px", fontSize: "20px" }}>
                <p>Invalid link</p>
            </div>
        );
    }

    if (error === 500) {
        return (
            <div style={{ margin: "20px", fontSize: "20px" }}>
                <p>Server error</p>
            </div>
        );
    }

    return (
        <div style={{ margin: "20px", fontSize: "20px" }}>
            <p>Loading...</p>
        </div>
    );
};

export default Share;
