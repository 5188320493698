import React, { useEffect, useState, useCallback, useRef } from "react";
import { Button, Image } from "react-bootstrap";
import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery from "react-photo-gallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCloudDownloadAlt, faArrowDown, faShare } from "@fortawesome/free-solid-svg-icons";
import Footer from "./Footer";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useCookies } from "react-cookie";

library.add(faCloudDownloadAlt);
library.add(faArrowDown);
library.add(faShare);

const UserGallery = (props) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [orientation, setOrientation] = useState("h");
    const galleryRef = useRef(null);
    const [cookies, setCookie, removeCookie] = useCookies();
    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const handleClick = (ref) =>
        ref.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });

    useEffect(() => {
        setOrientation(window.matchMedia("(orientation: landscape)").matches ? "h" : "v");

        const handleWindowResize = () => {
            setOrientation(window.matchMedia("(orientation: landscape)").matches ? "h" : "v");
        };

        window.addEventListener("resize", handleWindowResize);
    });

    const [copied, setCopied] = useState(false);

    const handleExit = (e) => {
        e.preventDefault();
        removeCookie("gallery_key");
        window.location.replace("/");
    };

    return (
        <div onContextMenu={(e) => e.preventDefault()}>
            <div
                className="cover"
                style={{
                    backgroundImage: `url(${
                        orientation === "h" ? props.galleryData.cover_h : props.galleryData.cover_v
                    })`,
                    backgroundSize: `cover`,
                }}
            >
                <a href="https://reginafoster.us" target="_blank">
                    <Image src="logo_2.png" className="h-48 px-4 py-4" />
                </a>

                <div className="title">
                    <div className="transparent">
                        <p className="h1 text-center">{props.galleryData.name}</p>
                        <p className="h4 text-center mb-10">{props.galleryData.date}</p>
                    </div>
                    <Button className="d-block m-auto" variant="dark" size="lg" onClick={() => handleClick(galleryRef)}>
                        <FontAwesomeIcon icon="arrow-down" />
                    </Button>
                </div>
            </div>

            <div ref={galleryRef}>
                <div className="toolbar">
                    {props.galleryData.archive && (
                        <>
                            <div className="float-right px-4 py-3 text-sm">
                                Link to share:&nbsp;&nbsp;
                                <CopyToClipboard text={props.galleryData.share_link}>
                                    <Button
                                        size="sm"
                                        variant="secondary"
                                        onClick={() => {
                                            setCopied(!copied);
                                            alert("Link copied to the clipboard!");
                                        }}
                                    >
                                        <FontAwesomeIcon icon="share" />
                                    </Button>
                                </CopyToClipboard>
                                &nbsp;&nbsp; Gallery download:&nbsp;&nbsp;
                                <Button size="sm" variant="secondary" href={props.galleryData.archive}>
                                    <FontAwesomeIcon icon="cloud-download-alt" />
                                </Button>
                            </div>
                        </>
                    )}
                </div>

                <div>
                    <Gallery photos={props.galleryData.image} onClick={openLightbox} direction="column" />
                    <ModalGateway>
                        {viewerIsOpen ? (
                            <Modal onClose={closeLightbox}>
                                <Carousel
                                    currentIndex={currentImage}
                                    views={props.galleryData.image.map((x) => ({
                                        ...x,
                                        srcset: x.srcSet,
                                        caption: x.title,
                                    }))}
                                />
                            </Modal>
                        ) : null}
                    </ModalGateway>
                </div>
            </div>

            {cookies.gallery_key && (
                <div className="text-center mt-2">
                    <a href="#" onClick={handleExit}>
                        [exit]
                    </a>
                </div>
            )}

            <Footer />
        </div>
    );
};

export default UserGallery;
