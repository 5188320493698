import React, { useEffect, useState } from "react";
import { Button, Card, Col, Image, Navbar, Row } from "react-bootstrap";
import Carousel, { Modal, ModalGateway } from "react-images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Masonry from "react-masonry-component";
import { API_URL } from "../config.js";
library.add(faSearchPlus);

const UserSubmission = (props) => {
    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [selected, setSelected] = useState(false);

    const [list, setList] = useState(
        Object.fromEntries(
            props.submissionData.image.map((item) => {
                return [item.name, false];
            })
        )
    );

    const [bonusClaimed, setBonusClaimed] = useState(false);
    const [allowedPhotos, setAllowedPhotos] = useState(parseInt(props.submissionData.count));

    const handleCheckbox = (event) => {
        setList({
            ...list,
            [event.target.id]: event.target.checked,
        });
    };

    const submitData = async () => {
        try {
            const data = {
                id: props.submissionData.id,
                name: props.submissionData.name,
                submission: [],
            };

            for (const [key, value] of Object.entries(list)) {
                if (value) {
                    data.submission.push(key);
                }
            }
            const res = await axios.post(API_URL + "/submission", data);
            return res.data;
        } catch (e) {
            return null;
        }
    };

    const handleSubmit = async () => {
        if (Object.values(list).filter((item) => item).length < allowedPhotos) {
            if (!window.confirm("Are you sure to submit less images?")) {
                return null;
            }
        }

        if (Object.values(list).filter((item) => item).length > allowedPhotos) {
            alert("Too many images were selected!");
            return null;
        }

        const result = await submitData();

        if (result) {
            setSelected(true);
        } else {
            alert("Internal server error!");
        }
    };

    useEffect(() => {
        if (Object.values(list).filter((item) => item).length === allowedPhotos && !bonusClaimed) {
            if (props.submissionData.id == "607cb2160b149f0dea1bc22b") {
                alert("You've got 10 bonus photos to select!");
                setAllowedPhotos(allowedPhotos + 10);
            } else {
                alert("You've got 2 bonus photos to select!");
                setAllowedPhotos(allowedPhotos + 2);
            }

            setBonusClaimed(true);
        }
    }, [list]);

    if (selected) {
        return (
            <Row className="mt-40">
                <Col md="12">
                    <Image src="logo.png" className="h-56 m-auto" />
                    <Row className="mt-8">
                        <Col md={{ span: 2, offset: 5 }} xs={{ span: 10, offset: 1 }}>
                            <p className="h5 text-center">Thank you for your submission! See you later!</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    } else {
        return (
            <>
                <Navbar bg="light" expand="lg" sticky="top">
                    <Navbar.Brand>
                        <Image src="logo.png" className="px-4 py-1 h-20" />
                    </Navbar.Brand>
                    {/* <Navbar.Toggle aria-controls="sticky-header" /> */}
                    {/* <Navbar.Collapse id="sticky-header"> */}
                    <Row>
                        <Col md="5">
                            <p className="mt-4 h6">
                                Please choose up to {allowedPhotos} images you would like to be retouched. Clicking on the image will select/deselect it. After you finish, click "Submit" button.
                            </p>
                        </Col>
                        <Col md="4">
                            <div className="mt-4 h6">
                                Images selected:&nbsp;
                                <p className="h5">
                                    {Object.values(list).filter((item) => item).length}/{allowedPhotos}
                                </p>
                            </div>
                        </Col>
                        <Col md="1">
                            <Button variant="secondary" className="mt-4" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                    {/* </Navbar.Collapse> */}
                </Navbar>

                <Masonry>
                    {props.submissionData.image.map((item, index) => (
                        <Col md="3" key={"col" + index}>
                            <Card>
                                <Card.Body>
                                    <input type="checkbox" id={item.name} onChange={handleCheckbox} />
                                    <label htmlFor={item.name} className="label-submission">
                                        <Image src={item.src.replace("submission", "thumb")} className="img-submission m-auto" />
                                    </label>

                                    <Button variant="secondary" onClick={() => openLightbox(index)} className="d-block mx-auto">
                                        <FontAwesomeIcon icon="search-plus" />
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Masonry>
                <ModalGateway>
                    {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={props.submissionData.image.map((x) => ({
                                    ...x,
                                    srcset: x.srcSet,
                                    caption: x.title,
                                }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </>
        );
    }
};

export default UserSubmission;

